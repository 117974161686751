import Cookies from 'js-cookie';
import List from 'list.js';

var body = $('body'),
  allPhones = 'only screen and (max-width : 768px)',
  tablet = 'only screen and (min-width: 769px) and (max-width: 1024px)',
  tabletDown = 'only screen and (max-width: 1024px)',
  desktop = 'only screen and (min-width: 1025px)',
  loadingSpinner = '<div class="cssload-container"><div class="cssload-speeding-wheel"></div></div>';

var listingToggle = function () {
  "use strict";
  if ($('html.localstorage').length) {
    $(".listing-toggle a").on('click', function (e) {
      e.preventDefault();
      $('.listing-toggle a').removeClass('active');
      $(this).addClass('active');
      $('body').removeClass('thumbnail-view').removeClass('listing-view').addClass($(this).attr('data-view'));
      localStorage.setItem('listingMode', $(this).attr('data-view'));
      setTimeout(function () {
        $(document).foundation('equalizer', 'reflow');
      }, 450);
      $(document).foundation('equalizer', 'reflow');
    });
    if (!localStorage.getItem('listingMode')) {  // list mode not set
      if ($('.listing-layout').length) {
        localStorage.setItem('listingMode', 'thumbnail-view');
      }
    } else {  // already set, so use it
      $('.listing-toggle a').removeClass('active');
      $('.listing-toggle a[data-view="' + localStorage.getItem('listingMode') + '"]').addClass('active');
      if ($('.listing-toggle').length) {
        $('body').removeClass('thumbnail-view').removeClass('listing-view').addClass(localStorage.getItem('listingMode'));
      }
      $(document).foundation('equalizer', 'reflow');
    }
  }
};

var handleBrokenImages = function () {
  "use strict";
  $("img").error(function () {
    $(this).attr('src', assetsUrl + 'img/no-image.jpg').addClass('no-image');
  });
};

var styledSelect = function () {
  "use strict";
  $("select.styled").each(function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();

    if ($this.parent('.select-wrapper').length === 0) {
      $this.wrap("<span class='select-wrapper'></span>");
      $this.after("<span class='holder'>" + selectedOption + "</span>");
    } else {
      $this.next(".holder").text(selectedOption);
    }

  });

  $(document).on('change', 'select.styled', function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();

    $this.next(".holder").text(selectedOption);
  });

};

var fixSelectizeFocus = function (selectize) {
  "use strict";
  // Fix unwanted behavior for Selectize. Warning: might have side effects.
  if (selectize.settings.mode == 'multi') {
    selectize.$control.off('mousedown');
    selectize.$control_input.off('mousedown');
    selectize.$control.on('mousedown', function () {
      // We click on the control, so we have to set the focus on it!
      // Even if openOnFocus is false. Which is more to control the default focus (first item of a forum)
      // or focus on keyboard navigation (tab).
      selectize.isFocused = false; // Set focus
      selectize.onMouseDown.apply(selectize, arguments);
      selectize.isFocused = true; // Open drop-down if needed
      return selectize.onMouseDown.apply(selectize, arguments);
    });
  }
};

var toggleSelectizeSelectedItem = function (selectize) {
  "use strict";
  selectize.$dropdown_content.on('mousedown', 'div[data-selectable].selected', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var selectedValue = $(this).data('value');

    selectize.removeItem(selectedValue, false);
    selectize.refreshOptions();

    return false;

  });
};

var hideOnClickOutside = function (selector) {
  "use strict";
  var outsideClickListener = function (event) {
    $target = $(event.target);
    if (!$target.closest(selector).length) {
      if ($(selector)[0].closest('.search-dropdown').classList.contains('open') && !event.target.classList.contains('focus')) {
        if (event.target.closest('.selectize-input') && !event.target.closest('.selectize-input').classList.contains('focus')) {
          return;
        }
        $container = selector.parents('.search-dropdown');
        $container.removeClass('open');
        removeClickListener();
      }
    }
  };

  var removeClickListener = function () {
    document.removeEventListener('click', outsideClickListener);
  };

  document.addEventListener('click', outsideClickListener);
};

var populateSelectOptions = function (selectize, trigger) {
  "use strict";
  $(document).on(trigger, function () {
    var $element = $(selectize.$input),
      value = $element.attr('data-populate-value'),
      ajaxUrl = $element.attr('data-populate-url');

    $element.parents('.search-dropdown').addClass('disabled');
    selectize.clearOptions();
    $.ajax({
      url: ajaxUrl + value + '.json',
      method: "GET",
      success: function (response) {
        $.each(response, function (index, val) {
          selectize.addOption({ value: val.slug, text: val.title });
        });
        selectize.refreshOptions(true);
        $element.parents('.search-dropdown').removeClass('disabled');
      },
      error: function (xhr, status, error) {
        console.log('Ajax Submit:' + status + ':' + error);
      }
    });

  });

  $(document).on(trigger + ":empty", function () {
    var $element = $(selectize.$input);

    selectize.clearOptions();
    selectize.refreshOptions(true);
    $element.parents('.search-dropdown').addClass('disabled');
  });
};

var selectizes = [];
var selectizeInputs = [];

var enableAjaxSelects = function () {
  "use strict";

  if ($('select.ajax-select').length) {
    $('select.ajax-select').each(function () {

      var $select = $(this),
        $container = $select.parents('.search-dropdown'),
        $label = $container.find('label'),
        $form = $select.parents('form'),
        setSelection = function (value) {
          //Set the selection text
          var selectionHtml = '';

          //Add the html if it does not excist
          if (!$container.find('.selectize-input .amount-label').length) {
            $container.find('.selectize-input').prepend('<div class="amount-label hidden"></div>');
          }

          //Check if 0 or more
          if (value && value !== '' && $.type(value) !== 'string' && value.length) {
            selectionHtml = value.length + ' valittu';
            $container.find('.selectize-input .amount-label').removeClass('hidden');
          } else {
            selectionHtml = '0 valittu';
            $container.find('.selectize-input .amount-label').addClass('hidden');
          }

          //Add the text
          $container.find('.selectize-input .amount-label').html(selectionHtml);
        },
        settings = {
          plugins: ['restore_on_backspace'],
          persist: false,
          hideSelected: false,
          highlight: true,
          closeAfterSelect: false,
          selectOnTab: true,
          maxOptions: 400,
          copyClassesToDropdown: false,
          render: {
            option: function (item, escape) {
              //Render the options html
              var optionText = item.text,
                optionValue = item.value;
              return '<div class="option" data-track-category="Dropdown select" data-track-label="Dropdown option"><span class="option-checkbox"></span>' + optionText + '</div>';
            }
          },
          onInitialize: function () {
            setSelection(this.items);
          },
          onChange: function (value) {
            var allFieldsEmpty = selectizeInputs.every(function (e) { return (e.val() === null || e.val() === ""); });

            sendEvent("Dropdown select", "click", "Dropdown option");

            if (allFieldsEmpty) {
              $('#clear-search').hide();

              if ($('body').hasClass('type-recipes')) {
                $('#recipes-default-view').show();
              }
            } else {
              $('#clear-search').show();
              $('#recipes-default-view').hide();
            }

            setSelection(value);
          },
          onDropdownOpen: function ($dropdown) {
            hideOnClickOutside($label);
            $container.addClass('open');
          },
          onDropdownClose: function ($dropdown) {
            // $container.removeClass('open');
          },
        },
        initSelect = $select.selectize(settings),
        selectApi = initSelect[0].selectize;
      selectizes.push(selectApi);
      selectizeInputs.push($select);

      //This fixes selectize focus issues
      fixSelectizeFocus(selectApi);
      toggleSelectizeSelectedItem(selectApi);

      $select.on('change', function () {
        var $this = $(this),
          populateAttr = $this.attr('data-populate');

        if (typeof populateAttr !== typeof undefined && populateAttr !== false) {
          $(document).find('[data-populate-watch="' + populateAttr + '"]').first().attr('data-populate-value', $select.val());
          if ($select.val() === '') {
            $(document).trigger('select:populate:' + populateAttr + ':empty');
          } else {
            $(document).trigger('select:populate:' + populateAttr);
          }
        }
        $form.submit();
      });

      var populateWatchAttr = $select.attr('data-populate-watch');

      if (typeof populateWatchAttr !== typeof undefined && populateWatchAttr !== false) {
        populateSelectOptions(selectApi, 'select:populate:' + populateWatchAttr);
      }

      // hideOnClickOutside($label);

      $label.on('click', function (e) {
        e.preventDefault();
        if ($container.hasClass('open')) {
          selectApi.close();
          $container.removeClass('open');
        } else {
          var list = document.querySelectorAll('.open');
          list.forEach(function (element) {
            element.className = element.className.replace('open', '');
          });
          selectApi.open();
          selectApi.blur();
        }
        return false;
      });


    });

    $('#clear-search').on('click', function () {
      $(this).hide();
      if ($('body').hasClass('type-recipes')) {
        $('#recipes-default-view').show();
      }
      selectizes.forEach(function (e) { e.clear(); });
    });
  }
};

function hideOnClickOutsideAjax(selector) {
  const outsideClickListener = (event) => {
    if ($('body').hasClass('results-open')) {
      const $target = $(event.target);
      if (!$target.closest(selector).length || $target.closest('.suggestions-background').length || $target.closest('.recipe-search__button').length) {
          $('body').removeClass('results-open');
          removeClickListener();
      }
    }
  }

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener);
  }

  document.addEventListener('click', outsideClickListener);
}

var searchAutoComplete = function () {
  "use strict";
  if ($('.autocomplete-search').length) {
    $.each($('.autocomplete-search'), function () {
      const $input = $(this);
      const $form = $input.parents('form')[0]
        ? $input.parents('form')
        : $input.data('type') === 'recipe'
          ? $input.parents('.recipe-search-form')
          : $input.data('type') === 'product'
            ? $input.parents('.product-search-form')
            : '';
      const $container = $form.find('.ajax-search-container');
      const $url = $form.data('suggestions');
      $input.autocomplete({
        serviceUrl: $url,
        type: 'GET',
        dataType: 'json',
        paramName: 'search',
        noCache: false,
        transformResult: function (response) {
          return {
            suggestions: $.map(response.data, function (dataItem) {
              return { value: dataItem.title, data: { title: dataItem.title, url: dataItem.url, pictureUrl: dataItem.pictureUrl } };
            })
          };
        },
        onSelect: function (suggestion) {
          //On select go to that page
          var url = suggestion.data.url;
          if (url.length) {
            window.location.href = url;
            sendEvent('Autocomplete Search', 'click', 'Autocomplete : ' + suggestion.data.title);
          }
        },
        onSearchStart: function (query) {
          $('body').addClass('fetching-result');
        },
        onSearchComplete: function (query, suggestions) {
          $('body').removeClass('fetching-result');
          $('body').addClass('results-open');
          hideOnClickOutsideAjax($container);
        },
        formatResult: function (suggestion, currentValue) {
          return $form[0] ? '<div class="suggestion">' + ($url.indexOf('restaurants') ? '' :
            ('<img alt="' + suggestion.data.title + '" src="' + suggestion.data.pictureUrl + '" /> ')) + '<div class="suggestion__text">' +
            $.Autocomplete.formatResult(suggestion, currentValue) + '</div>' +
            '</div>' : $.Autocomplete.formatResult(suggestion, currentValue);
        },
        minChars: 1,
        lookupLimit: 20,
        triggerSelectOnValidInput: false,
        preventBadQueries: true,
        autoSelectFirst: false,
        maxHeight: 'auto',
        width: 'auto',
        zIndex: '8000',
        appendTo: $container,
        forceFixPosition: false,
        orientation: 'top',
        preserveInput: true,
        showNoSuggestionNotice: false
      });
    });
  }
};

var ajaxFormInit = function () {
  "use strict";

  //DO ALL AJAX FORM RELATED STUFF HERE
  var $ajaxform = $('.ajax-form'),
    oldSearch = '',
    timestamps = [],
    setUrl = function (title, url) {
      // BUILD THE NEW URL
      var t = new Date().getTime();
      timestamps[t] = t;
      History.pushState({ timestamp: t }, title, url);
    },
    fetchResult = function ($form) {
      //THIS IS WHERE SUBMIT HAPPENSE
      var formDataRaw = $form.serializeArray(),
        filteredFormData = $(formDataRaw).filter(function () {
          return $.trim(this.value).length > 0;
        }),
        paramFormData = $.param(filteredFormData),
        $target = $($form.data('target')),
        $searchContainer = $('.ajax-search-container'),
        $loadingButton = $('.ajax-search-container__loading'),
        ajaxUrl = siteUrl + "ajax/" + $form.data('ajax'),
        newUrl = (paramFormData !== '') ? $form.attr('action').replace(/\/+$/, '') + '?' + paramFormData : $form.attr('action'),
        newTitle = $('title').html();

      //Add body class
      $('body').addClass('fetching-result').addClass('has-search-results');
      //Set the new URL
      setUrl(newTitle, newUrl);
      //Add loading to the result container
      $target.html('<div class="small-text-center padding">' + loadingSpinner + '</div>');
      $loadingButton.html(loadingSpinner);

      //Search input scrolls to results on mobile
      $searchContainer.find('button').on('click', function (e) {
        if (Modernizr.mq(allPhones)) {
          if (!$searchContainer.find('input').val().length) {
            e.preventDefault();
          }
          $('.show-results__link').click();
        }
      });

      //Ajax fetch the result
      $.ajax({
        url: ajaxUrl,
        data: paramFormData + '&' + csrfTokenName + '=' + csrfTokenValue,
        method: "POST",
        success: function (response) {
          //If response is valid
          if (response) {
            $target.html(response);
            const $foundElements = $($target).find('li');
            if ($foundElements.length > 0) {
              $('.results-amount').html($foundElements.length);
            } else {
              $('.results-amount').html('');
            }
            
            $target.imagesLoaded(function () {
              $(document).foundation('equalizer', 'reflow');
              listingToggle();
              handleBrokenImages();
              resultSorting();
              styledSelect();
            });


            setTimeout(function () {
              $('body').removeClass('fetching-result');
              $(document).foundation('equalizer', 'reflow');
            }, 150);

            document.dispatchEvent(
              new CustomEvent("ajax-results", {
                bubbles: true,
                detail: { text: () => response },
              }),
            );
          }
        },
        error: function (xhr, status, error) {
          console.log('Ajax Submit:' + status + ':' + error);
        }
      });
    };

  const $checkBoxes = $ajaxform.find('input[type=checkbox]');
  const $radioButtons = $ajaxform.find('input[type=radio]');

  const $inputs = $.merge($checkBoxes, $radioButtons);
  $inputs.each((i, $checkBox) => {
    $checkBox.addEventListener('change', (e) => {
      $ajaxform.trigger('submit');

      const $checkedCheckboxes = $ajaxform.find('input[type=checkbox]:checked');
      const $checkedRadioButtons = $ajaxform.find('input[type=radio]:checked');
      const checkedAmount = $checkedCheckboxes.length + $checkedRadioButtons.length;

      if (checkedAmount > 0) {
        $('.clear-filters-container').removeClass('hidden');
        $('.params-amount').html(checkedAmount)
        $('.filters-toggle span').html(checkedAmount)
      } else {
        $('.clear-filters-container').addClass('hidden');
      }
    })
  });
  
  // WHEN THE FORM SUBMITS...DO STUFF
  $ajaxform.on('submit', function (e) {
    e.preventDefault();
    document.body.classList.remove('results-open');
    var newSearch = $(this).serialize();
    if (newSearch != oldSearch) {
      fetchResult($(this));
      oldSearch = newSearch;
    }
    return false;
  });

  // Listening for events to determin back/forward actions
  History.Adapter.bind(window, 'statechange', function () {
    var State = History.getState();
    if (State.data.timestamp in timestamps) {
      // Deleting the unique timestamp associated with the state
      delete timestamps[State.data.timestamp];
    } else {
      // Manage Back/Forward button here
      window.location = State.url;
    }
  });

};

var resultSorting = function () {
  "use strict";
  var selectClass = '.js-sort-result';
  // IF IT EXCISTS
  if ($(selectClass).length) {
    // DO IT FOR EVERY js-sort-result select on this page
    $.each($(selectClass), function () {
      var $select = $(this),
        $selectOptions = $select.find('option'),
        sortListID = $select.data('listid'),
        sortCookie = Cookies.get(sortListID),
        sortingOptions = $.map($selectOptions, function (val, i) {
          var optionValue = val.value,
            optionValueArray = optionValue.split(', ');
          return optionValueArray[0];
        }),
        resultList = new List(sortListID, {
          //valueNames : sortingOptions,
          valueNames: sortingOptions,
          listClass: 'result-list',
          page: 500
        }),
        sortList = function (selection) {
          var selectionArray = selection.split(', '),
            sortValue = selectionArray[0],
            sortOrder = selectionArray[1];

          resultList.sort(sortValue, { order: sortOrder });
        };

      // When Changing the sort
      $(document).on('change', selectClass, function () {
        // filter items in the list
        sortList(this.value);
        // set cookie
        Cookies.set(sortListID, this.value);
      });

      // if cookie is set, sort the list
      if (sortCookie !== undefined) {
        //Cookie is set
        //Set the select and trigger change
        $select.val(sortCookie).trigger('change');
      } else {
        //Cookie is not set
        //Set the cookie
        Cookies.set(sortListID, $selectOptions.first().attr('value'));
      }

    });
  }
};

// GA EVENTS FOR CLICKS
var sendEvent = function (category, action, label) {
  "use strict";
  if (typeof ga === "function") {
    ga('send', 'event', category, action, label);
  }
  //console.log("ga('send', 'event', '"+category+"', '"+action+"', '"+label+"')");
};

var trackEvents = function () {
  "use strict";
  var location = window.location.href,
    $links = $('[data-track-category]');

  $(document).on('click', "[data-track-category]", function () {
    var $link = $(this),
      category = $link.attr('data-track-category'),
      action = 'click',
      label = $link.attr('data-track-label');

    sendEvent(category, action, label);
  });

};

$(document).ready(function () {
  "use strict";
  //ENABLE THE SELECTS
  enableAjaxSelects();
  searchAutoComplete();

  //enableAjaxInput();

  //ENABLE THE AJAX
  ajaxFormInit();

  //Enable sorting
  resultSorting();

  //RUN GA
  trackEvents();
});
