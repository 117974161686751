<template>
  <div class="search-sorting">
    <div class="search-sorting__title">{{ sortByText }}</div>
    <div class="select-container">
      <select
        :sorting.sync="sorting"
        @change="$emit('update:sorting', $event.target.value)"
      >
        <option v-for="(option, value) in options" :key="value" :value="option.value" :selected="option.value === sorting">{{ option.title|t }}</option>
      </select>
      <div class="arrow-container">
        <i class="ion-chevron-down cc_pointer"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {translation} from '../../js/translation'

export default {
  name: 'SearchSorting',
  props: {
    sorting: {
    },
    options: {
    },
    sortByText: {
      type: String,
      default: ''
    }
  },
  options: {
    type: Object,
    default: [],
    required: true,
  },
  filters: {
    t(val) {
      const locale = document.getElementById('productSearchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
};
</script>
