<template>
  <li class="recipe-item">
    <a :href="item.url" class="parent-style recipe-block" @click="setScrollPosition">
      <div class="recipe-block__bg padding--mini small-margin--bottom--mini medium-margin--bottom">
        <div class="ratio-box--3x2 recipe-image-container">
          <div v-if="isNew || item.easy == true" class="abbreviation-icons">
            <div v-if="isNew" class="abbreviation-icon abbreviation-icon--easy tooltip-container">
              <span>Uusi</span>
            </div>
            <div v-if="item.easy == true" class="abbreviation-icon abbreviation-icon--easy tooltip-container">
              <span>Helppo</span>
            </div>
          </div>
          <div class="ratio-box--inner">
            <template v-if="!!item.picture && !!item.picture[0]">
              <img :alt="item.picture[0].title" :data-src="item.picture[0].url" class="recipe-block__picture margin--bottom--mini lazyload" />
              <div class="image-overlay"></div>
            </template>
            <template v-else>
              <div class="image-not-found">
                <i class="icon-sydanmerkki_sydan"></i>
              </div>
            </template>
          </div>
        </div>
        <h5 class="heading-5 recipe-block__title sort-name">
          {{ item.title }}
        </h5>
      </div>
    </a>
  </li>
</template>

a recipe block
	div recipe-block__bg
		div tooltip-container
		img recipe-block__picture
		div image-overlay
		h5 recipe-block__title

<script>
import Vue from 'vue';
import moment from 'moment';

import 'lazysizes/plugins/attrchange/ls.attrchange';
import {translation} from '../js/translation'
import _ from 'lodash'

const arrowRight = '<span class="pl-3 group-hover:transform-right text-textPrimaryColor"><svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768"> <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path></svg></span>'

export default {
  name: 'CategorySearchItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    sectionType: {
      type: Array,
      default: []
    },
    actionUrl: {
      type: String,
      default: ''
    },
  },
  mounted: function() {
    // CSS object-fit fallback on Edge (and other browsers)
    // Read more, https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3

    // Detect objectFit support
    if ('objectFit' in document.documentElement.style === false) {

      // assign HTMLCollection with parents of images with objectFit to variable
      const container = document.getElementsByClassName('object-fit-fallback')

      // Loop through HTMLCollection
      for (let i = 0; i < container.length; i++) {
        // Asign image source to variable

        if (container[i].querySelector('img') !== null) {
          let imageSource = container[i].querySelector('img').src


          if (
            container[i].querySelector('img').getAttribute('data-srcset') !== null
          ) {
            const firstSrcSet = container[i]
              .querySelector('img')
              .getAttribute('data-srcset')
              .split(', ')[0]
            imageSource = firstSrcSet.split(' ')[0]
          }

          // Hide image
          container[i].querySelector('img').style.display = 'none'

          // Add background-size: cover
          container[i].style.backgroundSize = 'cover'

          // Add background-image: and put image source here
          container[i].style.backgroundImage = 'url(' + imageSource + ')'

          // Add background-position: center center
          container[i].style.backgroundPosition = 'center center'
        }
      }
    }
  },
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    setScrollPosition: function(event) {
      const element = event.target
      const offset = 200
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = element.getBoundingClientRect().top
      const elementPosition = elementRect - bodyRect
      const offsetPosition = elementPosition - offset
      localStorage.recipeScroll = offsetPosition;
    },
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM.YYYY');
        break;
      }
    }
  },
  computed: {
    _() {
      return _
    },
    isNew() {
      return this.item.postDate > moment().subtract(2, 'weeks').unix()
    },
    cookingTime() {
      let cookingTimeString = '';
      const fullCookingTime = this.item.passiveCookingTime + this.item.activeCookingTime;
      if (fullCookingTime < 15) {
        cookingTimeString = 'alle 15 min';
      } else if (fullCookingTime < 30) {
        cookingTimeString = 'alle 30 min';
      } else if (fullCookingTime > 29 && fullCookingTime < 61) {
        cookingTimeString = '30-60 min';
      } else {
        cookingTimeString = 'yli 60 min';
      }
      return cookingTimeString;
    }
  }
};
</script>
