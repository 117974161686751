import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {configureApi, apiUrl, getQueryParam, executeQuery} from './searchCommon';
import {translation} from './translation'

import CategorySearchMeta from '../vue/productSearch/CategorySearchMeta'
import CategorySearchList from '../vue/productSearch/CategorySearchList'
import Pagination from '../vue/productSearch/Pagination'
import CategoryFilter from '../vue/productSearch/CategoryFilter'
import CategoryCards from '../vue/productSearch/CategoryCards'

const searchApiToken = window.productSearchGraphQLApiKey || '';

// Site search application
function getcategorySearchLandingData() {
  return {
    allCategories: [],
    currentParentCat: null,
    sectionType: [],
    searchApi: axios.create(configureApi(apiUrl, searchApiToken)),
    searchQuery: '',
    searchCategories: [],
    categoryParams: false,
    searchFilters: [],
    propertyTypes: [],
    searchResults: [],
    searchSorting: 'postdate desc',
    categoryCards: [],
    filtersOpen: false,
    pagination: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 100,
      currentPage: 1
    },
    isLoading: true,
    isReady: false,
    hasParams: false,
    customers: []
  };
}

var filterChangeTimer;

var productSearchApp = function() {

// What to search for
const searchSite = document.getElementById('productSearchApp').dataset.site;
const searchSelectedSite = document.getElementById('productSearchApp').dataset.selectedSite;
const searchSections = ['KiltaProducts'];
const searchEntries = ['kiltaProducts_kiltaProducts_Entry'];
const searchCustomers = JSON.parse(document.getElementById('productSearchApp').dataset.customers);
const customers = searchCustomers.map(customer => {
  return {title: customer.title, slug: customer.slug, id: customer.id, level: null}
});

let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries + 
      `
      ... on ${entryType} {
        id
        title: kiltaMarketingName
        url
        postDate
        kiltaPropertyTypes {
          title
        }
        kiltaImage {
          url(width:600, position:"center")
          title
        }
        kiltaCustomer {
          ... on kiltaCustomers_kiltaCustomers_Entry {
            id
            title
            kiltaImage {
              url(width:600, position:"center")
              title
            }
          }
        }
      }
    `
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($needle: String, $limit: Int, $offset: Int, $orderBy: String, $site: [String], $relatedTo: [QueryArgument], $kiltaRetailProduct: Boolean, $sections: [String])
    {
      entries(limit: $limit offset: $offset orderBy: $orderBy search: $needle site: $site relatedTo: $relatedTo section: $sections kiltaRetailProduct: $kiltaRetailProduct) {
        ${searchQueries}
      }
      entryCount(limit: $limit offset: $offset orderBy: $orderBy search: $needle site: $site relatedTo: $relatedTo section: $sections kiltaRetailProduct: $kiltaRetailProduct)
    }
  `;

  new Vue({
    el: document.getElementById('productSearchApp'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategorySearchMeta,
      Pagination,
      CategoryFilter,
      CategoryCards
    },
    beforeCreate: function() {
    },
    created: function() {
      this.getCategories();
      this.customers = customers;
      this.allCustomers = customers;
      const searchParam = getQueryParam('q');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
        this.hasParams = true;
      }
      if(!!categoryIdParam) {
        this.hasParams = true;
        this.categoryParams = true;
      }
      if(!!pageParam) {
        this.hasParams = true;
      }
      window.addEventListener('click', (e) => {
        const filtersModal = document.getElementById('filters');
        if (filtersModal && !e.target.closest('.filters') && !e.target.closest('.filters-toggle') && !e.target.closest('.show-all-button') && !e.target.closest('.category-item-more') && !e.target.closest('.show-more-filters')){
          this.filtersOpen = false;
        }
      })
    },
    mounted: function() {
      // Focus style
      const elements = document.querySelectorAll("a, button, input[type='button'], select");
      let mouseDown = false;

      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('mousedown', () => {
          mouseDown = true;
        });
        
        elements[i].addEventListener('mouseup', () => {
          mouseDown = false;
        });
        
        elements[i].addEventListener('focus', (event) => {
          if (mouseDown) {
            event.target.blur();
          }
        });
      }
      if (!_.isEmpty(this.searchQuery)) {
        if (!this.categoryParams) {
          this.performSearch();
        }
      }
    },
    updated: function() {
      var button = document.querySelectorAll('.tooltip-container-vue');
      button.forEach(element => {
        if (!element.classList.contains('tooltip-action-set')) {
          element.classList.add('tooltip-action-set')
          $(element).hover(function () {
            var $this = $(this);
            $this.toggleClass('active');
          });

        }
      });
    },
    destroyed: function destroyed() {
    },
    watch: {
      'pagination.totalPages': function (val) {
        this.updatePagination();
      },
      'pagination.currentPage': function (val) {
        this.scrollup();
        this.pagination.offset = (this.pagination.currentPage - 1) * this.pagination.elementsPerPage;
        this.performSearch();
      },
      searchResults: function(val, oldVal) {
        const resultCustomers = val.map((result) => {
          if (result.kiltaCustomer[0]) {
            return result.kiltaCustomer[0].id;
          } else {
            return '';
          }
        })
        let filteredCustomers = this.allCustomers.filter((customer) => {
          return resultCustomers.includes(customer.id.toString());
        })
        if (filteredCustomers.length > 0) {
          this.customers = filteredCustomers;
        };

        let newSearchFilters = this.searchFilters.filter((filter) => {
          return filter.groupTitle != 'Valmistaja'
        });

        if (_.isEmpty(val)) {
          filteredCustomers = this.allCustomers;

          this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
        }

        newSearchFilters.push({
          'groupTitle': 'Valmistaja',
          'handle': 'kiltaCustomers',
          'id': 3,
          'categories': filteredCustomers,
          'multiple': true
        })
        if (resultCustomers.length > 0 && filteredCustomers.length > 0 && resultCustomers.length !== filteredCustomers.length) {
          this.searchFilters = newSearchFilters;
        }
      },
      searchQuery: function(val, oldVal) {
        if(val !== oldVal) {
          this.isReady = false
        }
      },
      searchFilters: function(val, oldVal) {
        const newCustomers = val.find((item) => item.id === 3);
        const oldCustomers = oldVal.find((item) => item.id === 3);

        if (oldCustomers && (oldCustomers.categories.length !== newCustomers.categories.length)) {
          return;
        }
        let categoryIds = [];
        const self = this;
        const searchParam = getQueryParam('q');
        const categoryParam = getQueryParam('cat');
        const categoryIdParam = getQueryParam('cat-id');
        const pageParam = getQueryParam('page');

        if (!!searchParam) {
          self.searchQuery = searchParam;
        }
        if(!!categoryIdParam) {
          const categoryIds = categoryIdParam.map(id => parseInt(id));
          let newCategories = []
      
          _.each(categoryIds, categoryId => {
            _.each(self.searchFilters, filter => {
              let categoryObjects = _.map(_.filter(filter.categories, item => {
                return item.id == categoryId;
              }), item => {
                let itemObject = {}
                
                itemObject.title = _.get(item, 'title')
                itemObject.slug = _.get(item, 'slug')
                itemObject.id = _.get(item, 'id')
                itemObject.level = _.get(item, 'level')
                itemObject.groupTitle = filter.groupTitle
                if (item.children) {
                  itemObject.siblings = item.children
                }
  
                return itemObject;
              })

              _.each(filter.categories, category => {
                  categoryObjects = _.union(categoryObjects, _.map(_.filter(category.children, item => {
                    return item.id == categoryId;
                  }), item => {
                    let itemObject = {}
                
                    itemObject.title = _.get(item, 'title')
                    itemObject.slug = _.get(item, 'slug')
                    itemObject.id = _.get(item, 'id')
                    itemObject.level = _.get(item, 'level')
                    itemObject.parent = _.get(item, 'parent')
                    itemObject.groupTitle = filter.groupTitle
                    if (category.children) {
                      itemObject.siblings = category.children
                    }
      
                    return itemObject;
                  }))
              })

              if (categoryObjects) {
                newCategories = _.union(newCategories, categoryObjects)
              }
            });
          })
          if (newCategories.length) {
            self.searchCategories = newCategories;
            const productCategory = _.find(self.searchCategories, category => category.groupTitle === 'Tuoteryhmä');
            if (productCategory) {
              if (productCategory.parent) {
                self.currentParentCat = productCategory.parent.slug;
              } else {
                self.currentParentCat = productCategory.slug;
              }
            } else {
              if (newCategories[0].groupTitle !== 'Valmistaja' && newCategories[0].groupTitle !== 'Ominaisuudet') {
                self.currentParentCat = newCategories[0].slug
              }
            }
          }
        }
        if(!!pageParam) {
          self.pagination.currentPage = parseInt(pageParam);
        }
        self.sectionType = searchSections;
      },
      currentParentCat: {
        handler: function(val, oldVal) {
        }
      },
      searchCategories: {
        handler: function(val, oldVal) {
        // if(!!oldVal && JSON.stringify(val) !== JSON.stringify(oldVal)) {
            // this.meta = _.get(getcategorySearchLandingData(), 'meta');
            if (!_.isEmpty(val)) {
              this.performSearch();
            } else {
              if (!_.isEmpty(this.searchQuery)) {
                this.performSearch();
              } else {
                this.searchResults = [];
                this.setHistory();
              }
            }
          // }
        },
        deep: true
      },
      searchSorting: function() {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
      t: function(val) {
        const locale = document.getElementById('productSearchApp').dataset.locale || 'fi-FI';
        const trans = translation[locale];
        return trans[val] || val;
      },
    },
    computed: {
    },
    methods: {
      clearSearch() {
        this.searchResults = [];
        let pageUrl = location.protocol + '//' + location.host + location.pathname;
        history.replaceState(null, null, pageUrl);
        this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
      },
      performSearch(init) {
        let self = this;

        if (init) {
          self.pagination = _.get(getcategorySearchLandingData(), 'pagination');
        }
        let searchTitle = !!self.searchQuery ? `${self.searchQuery}` : '';

        let searchCategoriesArray = [];
        if (!_.isEmpty(self.searchCategories)) {
          _.map(self.searchCategories, o => {
            searchCategoriesArray.push(o.id)
          })
        }
        // Set the variables we will pass in to our query
        let variables = {
            sections: searchSections,
            needle: searchTitle ? `title:${searchTitle} OR kiltaMarketingName:${searchTitle}` : '',
            limit: self.pagination.elementsPerPage || 24,
            offset: self.pagination.offset || 0,
            orderBy: self.searchSorting,
            site: [searchSite],
            kiltaRetailProduct: searchSelectedSite === 'consumers' ? true : false
        };

        if (searchCategoriesArray.length > 0 ) {
          const propertyTypes = self.propertyTypes.map(item => item.id);
          const customerIds = customers.map(item => item.id);
          const propertyTypeCats = searchCategoriesArray.filter(category => propertyTypes.includes(category) || customerIds.includes(category))
          const otherCats = searchCategoriesArray.filter(category => !propertyTypes.includes(category) && !customerIds.includes(category)).map(item => item)
          let relatedTo = propertyTypeCats || [];
          if (otherCats.length > 0) {
            relatedTo = propertyTypeCats.concat(otherCats)
          }
          variables['relatedTo'] = relatedTo.length > 0 ? ['and', ...relatedTo] : [];
        }

        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          self.isLoading = true;
          self.setHistory();
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = _.filter(dataPath.entries, entry => !!entry.id);
            self.pagination.totalCount = dataPath.entryCount;
            self.pagination.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.pagination.elementsPerPage || 24));;
            if (!!data.data.meta) {
              self.allCategories = _.uniq(_.map(_.flatMap(data.data.meta.entries, 'newsCategory'), 'title'));
            }
            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 800);
      },
      setHistory: function() {
        let self = this;
        let paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategories[0], 'slug')) {
          _.forEach(self.searchCategories, o => {
            paramString += !!paramString ? ('&cat=' + o.slug) : (paramString += '?cat=' + o.slug);
            paramString += !!paramString ? ('&cat-id=' + o.id) : (paramString += '?cat-id=' + o.id);
          })
        }
        if(!_.isEmpty(self.startDate)) {
          paramString += !!paramString ? ('&start=' + moment(self.startDate.start).format('YYYY-MM-DD')) : (paramString += '?start=' + moment(self.startDate.start).format('YYYY-MM-DD'));
          paramString += !!paramString ? ('&end=' + moment(self.startDate.end).format('YYYY-MM-DD')) : (paramString += '?end=' + moment(self.startDate.end).format('YYYY-MM-DD'));
        }
        if(!!self.pagination.currentPage && (!!self.searchQuery || !!_.get(self.searchCategories[0], 'slug'))) {
          paramString += !!paramString ? ('&page=' + self.pagination.currentPage) : (paramString += '?page=' + self.pagination.currentPage);
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function() {
        var top = 0;

        setTimeout(function() {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
      updatePagination() {
        const { pagination, currentPage, totalPages } = this;
        pagination.pages = [];
        for (let i = 0; i < totalPages; i++) {
          const index = i + 1;
          pagination.pages.push({
            text: index,
            url: this.getPageUrl(index),
            current: index === currentPage
          });
        }
        pagination.hasNext = currentPage < totalPages;
        pagination.hasPrev = currentPage > 1;
      },
      getCategories: function() {
        let self = this;
        const categoryQuery =
        `
        query searchQuery($site: [String])
          {
            kiltaFoodTypes: categories(group: "kiltaFoodTypes" level: 1 site: $site) {
              title
              slug
              id
              level
              parent {
                slug
              }
              children {
                title
                slug
                id
                level
                parent {
                  slug
                }
              }
            }
            kiltaPropertyTypes: categories(group: "kiltaPropertyTypes" level: 1 site: $site) {
              title
              slug
              id
              level
            }
          }
        `;
        executeQuery(axios.create(configureApi(apiUrl, searchApiToken)), categoryQuery, {
            limit: 100,
            site: [searchSite],
          }, (data) => {

          const kiltaPropertyTypes = data.data.kiltaPropertyTypes;
          self.propertyTypes = data.data.kiltaPropertyTypes;

          self.searchFilters = [
            {
              'groupTitle': 'Tuoteryhmä',
              'handle': 'kiltaFoodTypes',
              'id': 1,
              'categories': data.data.kiltaFoodTypes,
              'multiple': true
            },
            {
              'groupTitle': 'Ominaisuudet',
              'handle': 'kiltaPropertyTypes',
              'id': 2,
              'categories': kiltaPropertyTypes,
              'multiple': true
            },
            {
              'groupTitle': 'Valmistaja',
              'handle': 'kiltaCustomers',
              'id': 3,
              'categories': customers,
              'multiple': true
            },
          ];

          self.categoryCards = data.data.kiltaFoodTypes;
          if (!self.hasParams) {
            self.isLoading = false;
          }
        });
      }
    },
  });
};

!!document.getElementById('productSearchApp') && productSearchApp();
