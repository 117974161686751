var footerToggleChildren = function() {
  "use strict";
  var toggleLink;
  toggleLink = $('.footer-navigation .toggle-children');
  toggleLink.on('click', function(e) {
    var $link, targetId;
    e.preventDefault();
    $link = $(this);
    targetId = $link.attr('href');
    $link.toggleClass('showing-children');
    $(targetId).toggleClass('show-children');
    return false;
  });
};

var disableEmptyFooterLinks = function() {
  "use strict";
  var $links = $('.footer-navigation a');
  $links.click(function(e){
    var $this = $(this);
    if($this.attr('href') === "#") {
      $this.next('.toggle-children').click();
      e.preventDefault();
      return false;
    }
  });
};

$(document).ready(function() {
  "use strict";
  footerToggleChildren();
  disableEmptyFooterLinks();
});
