<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader mb-10 xxl:mb-20 px-5 xxl:px-10 w-full md:w-1/2 lg:w-1/3" v-show="isLoading || !isReady" leave-stagger="500">
      <div class="flex flex-col h-full rounded overflow-hidden">
        <div class="mb-4 block relative aspect-ratio-box aspect-ratio-box--8-5">
          <div class="cardLoader__image absolute pin-t pin-l w-full h-full block object-cover"></div>
        </div>
        <div class="line-small"></div>
        <h3 class="line-medium-large mt-8"></h3>
        <div class="line-medium mt-4"></div>
        <div class="line-small mt-12"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'CategoryLoadingIndicator',
  props: ['isLoading', 'isReady', 'sectionType']
};
</script>

<style lang="scss">
  .cardLoader {
    &__image {
      background-color: #eeeeee;
      background: linear-gradient(
        to right,
        #eeeeee 10%,
        #dddddd 20%,
        #eeeeee 40%
      );
      animation: image-loading 2s linear infinite;
      background-size: 800px auto;
    }
    [class*='line'] {
      animation: loading 2s linear infinite;
      background: linear-gradient(to right, #eee 8%, #ddd 16%, #eee 30%);
      background-size: 800px auto;
    }
    .line-medium {
      width: 150px;
      height: 12px;
      background-color: #eeeeee;
    }
    .line-medium-large {
      width: 80%;
      max-width: 480px;
      height: 12px;
      background-color: #eeeeee;
    }
    .line-large {
      width: 100%;
      height: 12px;
      background-color: #eeeeee;
    }
    .line-small {
      width: 100px;
      height: 12px;
      background-color: #eeeeee;
    }
    .line-micro {
      width: 50px;
      height: 12px;
      background-color: #eeeeee;
    }
    .line-blue-dark {
      background-color: #0E274D;
      animation: loading 2s linear infinite;
      background: linear-gradient(to right, #1c3d5a 8%, #12283a 16%, #1c3d5a 30%);
      background-size: 800px auto;
    }
  }

  @keyframes image-loading {
    0% {
      background-position: -800px;
    }
    100% {
      background-position: 800px;
    }
  }

  @keyframes loading {
    0% {
      background-position: -800px;
    }
    100% {
      background-position: 800px;
    }
  }

  .fadeIn-enter-active,
  .fadeIn-leave-active {
    transition: opacity 0.5s;
  }
  .fadeIn-enter, .fadeIn-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }

</style>