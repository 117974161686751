<template>
  <nav class="pagination" aria-label="Pagination">
    <div class="pagination-container">
      <ul v-if="pages > 1" class="pt-6 list-none flex items-center justify-center small-text line-height-none">
        <li :class="current > 1 ? 'has-previous' : ''" @click="current > 1 ? changePage(current - 1) : ''">
          <span class="pr-6 inline-block">
            <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
              <path d="M96.735 410l241.085 241.085-39.27 39.27-298.55-298.55 314.161-314.161 37.318 37.319-242.261 242.261h658.781v52.777h-671.265z"></path>
            </svg>
          </span>
        </li>
        <template v-for="n in (current - 2, current + 2)" >
          <li :class=" n !== current ? 'not-current' : ''" :key="n" v-if="n > 0 && n >= current - 2 && n <= pages" @click="changePage(n)" class="number">
            <span :class=" n === current ? 'current' : 'px-10 text-ebonyClay hover:opacity-50' " :rel="n > current ? 'next' : n < current ? 'prev' : false" class="">{{ n }}</span>
          </li>
        </template>
        <li :class="current < pages ? 'has-next' : ''" @click="current < pages ? changePage(current + 1) : ''">
          <span class="pl-6 inline-block">
            <svg class="fill-current block" width="14px" height="14px" viewBox="0 0 768 768">
              <path d="M667.859 357.544l-242.748-242.748 39.104-39.104 303.784 303.784-312.832 312.832-37.973-37.973 243.089-243.089h-660.284v-53.702h667.86z"></path>
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    }
  },
  methods: {
    changePage(page) {
      this.$emit('update:current', page)
    }
  },
}
</script>
