var enableMultiSearch = function() {
  "use strict";

  $('.multi-search').each(function() {

    var $multiSearch = $(this);
    if ($multiSearch.length) {
      var $input = $multiSearch.find('.multi-search__input');
      var $button = $multiSearch.find('.multi-search__button');
      var $targets = $multiSearch.find('.multi-search__target');
      var $form = $multiSearch.find('.multi-search__form');

      $input.on('keyup', function(e){
        if (e.keyCode == 13) {
          $targets.first().click();
        } else {
          $targets.each(function(){
            var $this = $(this);
            var val = $input.val();
            if (val.length) {
              $this.find('.multi-search__target__content').html(val);
              $this.removeClass('hide');
            } else {
              $this.addClass('hide');
            }
          });
        }
      });
      $targets.each(function(){
        var $this = $(this);
        var $content = $this.find('.multi-search__target__content');
        if ($content.html().length) {
          $this.removeClass('hide');
        }
        $this.click(function(){
          var query = $content.html();
          if (query.length > 3) {
            $form.find('input[type="search"]').val(query);
            $form.attr('action', $this.data('target') + $this.data('handle') + $input.val());
            $form.submit();
          } else {
            //alert('Your query is too short');
            alert('Hakusanasi on liian lyhyt.');
          }
          return false;
        });
      });
      $button.click(function(){
        if($('body').hasClass('multi-search--closed')) {
          $('body').removeClass('multi-search--closed');
        } else {
          //$targets.first().click();
          $('body').addClass('multi-search--closed');
        }
      });

      $('#page').on('click', function() {
        if(!$('body').hasClass('multi-search--closed')) {
          $button.click();
        }
      });
    }
    $('.js-focus-on-search').on('click', function(e) {
      $('.multi-search__button').first().click();
      e.preventDefault();
    });
  });
};

$(function() {
  "use strict";
  enableMultiSearch();
});
