<template>
    <div class="row" data-equalizer="products-new">
        <div
            class="small-6 medium-4 large-3 columns end margin--top--mini"
            v-for="(cat, key) in categoryFilters"
            :key="'product-category-block' + key"
        >
            <div
                class="product-category-block" 
                data-equalizer-watch="products-new"
                @click="categorySelector(cat, cat.groupTitle, cat.children, cat.slug);"
                :id="'product-category-block' + cat.id"
            >
                <div class="product-category-block__bg">
                    <div class="product-category-image">
                        <img :src="'/assets/img/category_icon_' + cat.id + '.svg'" />
                    </div>
                    <h5 class="product-category-block__title sort-name">{{ cat.title }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import {translation} from '../../js/translation'

export default {
  name: 'CategoryCards',
  props: {
    categoryFilters: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
    }
  },
  methods: {
    categorySelector: function(cat, groupCat, children, parentCat) {
      let newCats = []
      if (groupCat !== 'Valmistaja' && groupCat !== 'Ominaisuudet') {
          this.$emit('update:current-parent-cat', parentCat)
        }
      this.selectedCategories.forEach(element => {
        newCats.push(element)
      })
      if (!_.get(cat, 'id')) {
        newCats = _.reject(newCats, {
          groupTitle: this.categoryFilters[0].groupTitle,
        })
      } else {
        if (_.find(this.selectedCategories, {id: cat.id})) {
          _.remove(newCats, {id: cat.id})
        } else {
          let catObj = {}
          if (!cat) {
          } else {
            catObj.title = _.get(cat, 'title')
            catObj.slug = _.get(cat, 'slug')
            catObj.id = _.get(cat, 'id')
            catObj.level = _.get(cat, 'level')
            if (children) {
              catObj.siblings = children;
            }
            catObj.groupTitle = !!groupCat ? groupCat : undefined
            newCats.push(catObj)
          }
        }
      }
      this.$emit('update:selected-categories', newCats)
      
    const element = document.querySelector('.filters')
    const offset = 200
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    })

    },
  },
}
</script>
