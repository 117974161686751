var recipeCalculator = function() {
  "use strict";

  var $buttons = $('.single-recipe__servings__buttons');
  if ($buttons.length) {
    var $input = $('input[name="recipe-servings"]'),
        servings = Number($input.val()),
        $numbers = $('.calculate');

    // force numbers only input on text field
    $input.numbers().data('original', servings);
    // save original values
    $numbers.each(function() {
      var serving = 0,
          value = Number($(this).html());
      if(value) {
        serving = value/servings;
      }
      $(this).attr('data-original', value).attr('data-serving', serving);
    });

    var calculate = function(multiplier) {
      $numbers.each(function() {
        var result = Number($(this).data('serving')) * multiplier,
            decimals = 3;
        if($(this).data('decimals')) {
          decimals = $(this).data('decimals');
        }
        result = parseFloat(result).toFixed(decimals); // 0.078
        /*
        if(String(result).length > 8) {
          //console.log(result);
          var sp = String(result).split(".");
          if(sp[1] > 100) {
            //console.log('sp'+ sp[1]);
            result = parseFloat(result).toFixed(1); // 0.1
          } else {
            result = parseFloat(result).toFixed(3); // 0.078
          }
        }
        */
        $(this).html(result);
      });
    };

    $buttons.find('.increase').on('click', function(){
      var newServings = Number($input.val())+10;
      $input.val(newServings);
      calculate(newServings);
    });
    $buttons.find('.decrease').on('click', function(){
      var newServings = Number($input.val())-10;
      if(newServings < 1) newServings = 1;
      $input.val(newServings);
      calculate(newServings);
    });
    $input.on('keydown keyup keypress change', function() {
      calculate($(this).val());
    });
  }
};

$(function() {
  "use strict";
  recipeCalculator();
});
