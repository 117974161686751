import jQuery from 'jquery';

import fastclick from 'fastclick';
import foundation from 'foundation-sites'
import 'foundation-sites/js/foundation/foundation.dropdown';
import 'foundation-sites/js/foundation/foundation.equalizer';
import 'foundation-sites/js/foundation/foundation.tab';
import 'foundation-sites/js/foundation/foundation.accordion';
import "jquery-placeholder"
import "js-cookie"
import "imagesloaded"
// import "detectizr"
import "selectize"
import "./plugins/jquery.royalslider.min.js"
import "./plugins/jquery.numberLetters.js"
import "./plugins/jquery.easy-autocomplete"
import "./plugins/jquery.autocomplete"

import "flickity"
import './plugins/modernizr-custom';
import './plugins/jquery.fitvids';
import './plugins/nanoscroller';
import './plugins/jquery.history';

import "./helpers/es6-promise"
import "./helpers/iePolyfills"

//Your own scripts
import "./categorySearch";
import "./productCategorySearch";
import "./_globals";
import "./_mobileMenu";
import "./_scrollToElement";
import "./_footerNavigation";
import "./_ajax";
import "./_multiSearch";
import "./_recipeCalculator";
import "./_toggleChildren";
import "./_slideshows";
global.$ = global.jQuery = jQuery;
$(window).on('load', function () {
  $(document).foundation();
});

// main.js
var imagesLoaded = require('imagesloaded');

// provide jQuery argument
imagesLoaded.makeJQueryPlugin($);