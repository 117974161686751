<template>
  <div class="category-search-meta">
    <div class="amount-container">
      <transition name="fade" mode="out-in">
        <div
          class="search-results-loading-text"
          style="z-index: -1"
          v-show="isLoading"
        >
          <div>{{"Searching"|t}}</div>
        </div>
      </transition>
      <div v-if="itemsAmount > 0 && !isLoading">
        <div class="amount-text">
          <span class="total-amount">{{totalItems}}</span><span> {{ 'Recipes'|t }}</span>
        </div>
      </div>
    </div>
    <search-sorting
      :sort-by-text="sortByText"
      :sorting.sync="searchSorting"
      :options="sortingOptions"
    ></search-sorting>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchSorting from './SearchSorting'
import {translation} from '../js/translation'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    itemOffset: {
      type: Number,
      default: 0
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    'sectionType': {
      type: Array,
      default: []
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc'
        },
        {
          title: 'Oldest first',
          value: 'postdate asc'
        },
        {
          title: 'Title A-Z',
          value: 'title asc'
        },
        {
          title: 'Title Z-A',
          value: 'title desc'
        }
      ]
    },
    sortByText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchSorting: ''
    }
  },
  computed: {
    itemsAmount: function() {
      const itemsLeft = this.totalItems - this.itemOffset > this.elementsPerPage ? this.itemOffset + this.elementsPerPage : this.totalItems;

      return itemsLeft;
    }
  },
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    }
  },
  components: {
    SearchSorting
  },
};
</script>
