<template>
  <div class="relative -mx-5 xxl:-mx-10 flex flex-wrap" :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <transition name="fade" mode="out-in">
      <template v-if="items.length > 0">
        <ul class="recipe-search-list result-list">
          <category-search-item
            v-for="(item, key) in items"
            :key="key"
            :item="item"
            :is-loading="isLoading"
            :section-type="sectionType"
            :action-url="actionUrl"
            :is-logged-in="isLoggedIn"
          ></category-search-item>
        </ul>
      </template>
    </transition>
    <transition name="fadeIn" mode="in-out">
      <template v-if="(!_.isEmpty(query) && _.size(items) === 0 && isReady) || (selectedCategories.length > 0 && !isLoading && _.size(items) === 0 && isReady)">
        <h4 class="no-results-title heading-4 mx-auto py-8 text-center">{{"No results found"|t}}</h4>
      </template>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue';
import {translation} from '../js/translation'
import CategorySearchItem from './CategorySearchItem'
import CardLoadingIndicator from './CardLoadingIndicator'
import _ from 'lodash'

export default {
  name: 'CategorySearchList',
  props: {
    sectionType: {
      type: Array,
      default: []
    },
    items: {
      type: Array,
      default: [],
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    },
    query: {
      type: String,
      default: '',
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    actionUrl: {
      type: String,
      default: ''
    },
    currentUser: {
      type: Object,
      default: {}
    }
  },
  components: {
    CategorySearchItem,
    CardLoadingIndicator,
  },
  filters: {
    t(val) {
      const locale = document.getElementById('searchApp').dataset.locale || 'fi-FI';
      const trans = translation[locale];
      return trans[val] || val;
    },
  },
  methods: {
    listMinHeight: function() {
      let rowHeight = 310;
      let rowAmount = 2;
      return rowAmount * rowHeight + 'px';
    }
  },
  updated: function () {
    const resultsListElement = document.querySelector('.result-list');
    if (resultsListElement) {
      $(document).foundation('equalizer', 'reflow');

      if (localStorage.recipeScroll != null) {
        window.scrollTo({
          top: localStorage.recipeScroll,
        })
        localStorage.recipeScroll = null;
      }
    }
    if ($('.favorite').length) {
    var favorite = $('.favorite').not('.not-loggedIn, .favorite-set'),
      url = window.location.href;
    if (!$('.notification-wrapper').length) {
      $('#content').prepend('<div class="notification-wrapper"><div class="notification"><p></p></div></div>');
    }
    favorite.addClass('favorite-set')
    favorite.on("click", function (e) {
      e.preventDefault();
      var $favoriteLink = $(this);
      $.ajax($favoriteLink.data("href"), {
        success: function (data) {
          var isFavorite = ($favoriteLink.data("href") === $favoriteLink.data("remove-url")) ? false : true;
          var link = (isFavorite) ? $favoriteLink.data("remove-url") : $favoriteLink.data("add-url");
          var text = (isFavorite) ? $favoriteLink.data("remove-text") : $favoriteLink.data("add-text");
          var notification = (isFavorite) ? "Resepti tallennettu" : "Resepti poistettu tallennetuista";

          $('.notification p').text(notification);
          $favoriteLink.data("href", link);
          $favoriteLink.find(".favorite-text").text(text);
          if ($favoriteLink.data("new-card")) {
            if (isFavorite) {
              $favoriteLink.html('<svg fill="#E10D49" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 6 2 C 5.861875 2 5.7278809 2.0143848 5.5976562 2.0410156 C 4.686084 2.2274316 4 3.033125 4 4 L 4 22 L 12 19 L 20 22 L 20 4 C 20 3.8625 19.985742 3.7275391 19.958984 3.5976562 C 19.799199 2.8163086 19.183691 2.2008008 18.402344 2.0410156 C 18.272119 2.0143848 18.138125 2 18 2 L 6 2 z"/></svg>');
            } else {
              $favoriteLink.html('<svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 6.0097656 2 C 4.9143111 2 4.0097656 2.9025988 4.0097656 3.9980469 L 4 22 L 12 19 L 20 22 L 20 20.556641 L 20 4 C 20 2.9069372 19.093063 2 18 2 L 6.0097656 2 z M 6.0097656 4 L 18 4 L 18 19.113281 L 12 16.863281 L 6.0019531 19.113281 L 6.0097656 4 z"/></svg>');
            }
          }
          $('.notification-wrapper').addClass('normal-notification');
          $(document).trigger('notify');

        }
      });
    });

  }
  },
  computed: {
    _() {
      return _
    },
    isLoggedIn: function() {
      return !_.isEmpty(this.currentUser);
    }
  },
};
</script>
